// Dependency
import * as React from "react"
import {graphql} from "gatsby";

// Components
import Layout from "../../components/layouts";
import Hero from "../../components/hero/hero";
import StickyGrid from "../../components/sticky-grid/sticky-grid";
import BoxGrid from "../../components/box-grid/box-grid";
import TeamGrid from "../../components/team-grid/team-grid";
import PerkSwiper from "../../components/perk-swiper/perk-swiper";

// Styles

// Assets
import hero from "../../images/hero/Corporatefinance.jpg";
import Quote from "../../components/quote/quote";
import HalfGrid from "../../components/half-grid/half-grid";
import FooterText from "../../components/footer-text/footer-text";


// Template
const WorkArea = (props) => {

    const pageData = props.data.allDatoCmsWorkArea.nodes;

    const metaData = {
        name: pageData[0].title,
        image:{
            src: hero,
        }
    }
    return (
        <Layout data={metaData} path={props.location} locale={props.pageContext.locale}>
            <Hero
                featuredImage={pageData[0].desktopHero.url}
            >
                <div dangerouslySetInnerHTML={{__html: pageData[0].heroContent}}></div>
            </Hero>

            <PerkSwiper data={pageData[0].perks} />

            <StickyGrid
                title={pageData[0].subtitle}
                subTitle={pageData[0].contentTitle}
                pageContent={pageData[0].content}
                coworkersData={pageData[0].contacts}
                locale={props.pageContext.locale}
            />


            {
                pageData[0] && pageData[0].quote &&   <Quote data={pageData[0].quote} />
            }

            {
                pageData[0] && pageData[0].entText && <HalfGrid locale={props.pageContext.locale} cname={"cd-half-grid-ent"} subTitle={pageData[0].entSubtitle} link={pageData[0].entLink} linkText={pageData[0].entLinkText} image={pageData[0].entImage} content={pageData[0].entText} />
            }

            {
                pageData[0] && pageData[0].invText && <HalfGrid locale={props.pageContext.locale} cname={"cd-half-grid-invest"} subTitle={pageData[0].invSubtitle} link={pageData[0].invLink} linkText={pageData[0].invLinkText} image={pageData[0].invImage} content={pageData[0].invText} />
            }

            {
                pageData[0] && pageData[0].workAreas && <BoxGrid locale={props.pageContext.locale} data={pageData[0].workAreas} />
            }

            {
                pageData[0] && pageData[0].team && <TeamGrid locale={props.pageContext.locale} data={pageData[0].team} />
            }

            <FooterText />

        </Layout>
    )
}

export default WorkArea;

// GraphQL to get Dato data
export const query = graphql`
  query ($slug: String!, $locale: String!){
    allDatoCmsWorkArea(
      filter: {slug: { eq: $slug }, locale: {eq: $locale}}
    )
    {
      nodes {
        seoMetaTags {
          tags
          id
        }
        desktopHero{
            url
        }
        heroContent
        perks{
          content
        }
        title
        subtitle
        contentTitle
        slug
        content
        contacts{
          name
          title
          slug
          image{
            fluid{
              ...GatsbyDatoCmsFluid
            }
          }
        }
        quote{
          content
        }
        entSubtitle
        entText
        entLink
        entLinkText
        entImage{
          fluid{
            ...GatsbyDatoCmsFluid
          }
        }
        invSubtitle
        invText
        invLink
        invLinkText
        invImage{
          fluid{
            ...GatsbyDatoCmsFluid
          }
        }
        workAreas{
          title
          slug
          description
        }
        team{
          name
          title
          slug
          image{
            fluid{
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
