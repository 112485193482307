// Dependency
import React from "react"
import Reveal from "react-reveal/Reveal";
import {Link} from "gatsby";
import Img from "gatsby-image";


// Styles
import * as stickyStyles from './sticky-grid.module.scss'
import * as navStyles from "../coworker-nav/coworker-nav.module.scss";
import { useTranslation } from "react-i18next";

// Assets

// Template
const StickyGrid = (props) => {

    const { t } = useTranslation()

    return (
        <section className={stickyStyles.cdStickyGridSection}>
            <div className="cd-max-width">
                <div className={stickyStyles.cdStickyGrid}>
                    <div className={stickyStyles.cdStickyGridLeft}>
                        <div className={stickyStyles.cdStickyGridLeftContent}>
                            <Reveal effect="typewriter">
                                <div className="">
                                    <h6>{props.title}</h6>
                                </div>
                            </Reveal>
                            <h3>{props.subTitle}</h3>
                        </div>
                    </div>
                    <div className={stickyStyles.cdStickyGridRight}>
                        <div dangerouslySetInnerHTML={{ __html: props.pageContent}}></div>
                        {
                            props && props.coworkersData &&    <div className={stickyStyles.cdStickyGridRightContent}>
                                <h6>{t('contact.contactPerson')}</h6>
                                <div className={stickyStyles.cdStickyGridCoworkers}>
                                    {props.coworkersData.map( (coworkerItem, i) => {
                                        return (
                                            <Link key={i} to={`/${props.locale}/coworker/${coworkerItem.slug}`}  className={navStyles.cdCoworkerNavItem}>
                                                <div className={`${navStyles.cdCoworkerNavItemImg} cd-background-img`}>
                                                    <Img fluid={coworkerItem.image.fluid} alt={coworkerItem.name} />
                                                </div>
                                                <div className={navStyles.cdCoworkerNavItemContent}>
                                                    <p>{coworkerItem.name}</p>
                                                    <span>{coworkerItem.title}</span>
                                                </div>
                                            </Link>
                                        )
                                    } )}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StickyGrid
