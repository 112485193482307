// Dependency
import React from "react"
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';

// Styles
import * as perkStyles from './perk-swiper.module.scss'

// Assets
import leaf from './assets/dark-leaf.svg'

// install Swiper modules
SwiperCore.use([Navigation]);

// Template
const PerkSwiper = (props) => {

    return (
        <section className={perkStyles.cdPerkSwiperSection}>
            <div className="cd-max-width">
                <Swiper className={`cd-perk-swiper cd-swiper`}
                        spaceBetween={20}
                        slidesPerView={'auto'}
                        slidesPerGroup={1}
                        grabCursor={true}
                        watchSlidesVisibility={true}
                        navigation={ {
                            nextEl: '.cd-perk-next',
                            prevEl: '.cd-perk-prev'
                        }}
                        breakpoints={{
                            900: {
                                spaceBetween: 20,
                                slidesPerView: 'auto',
                                slidesPerGroup: 1
                            },
                            0:{
                                spaceBetween: 20,
                                slidesPerView: 1,
                                slidesPerGroup: 1
                            }
                        }}
                >
                    {props.data.map( (perk, i) => {
                        return (
                            <SwiperSlide key={i} className={perkStyles.swiperSlide}>
                                <div className={perkStyles.cdPerkItem}>
                                    <div className={perkStyles.cdPerkItemLeft}>
                                        <img src={leaf} alt="Gro Leaf Icon" />
                                    </div>
                                    <div className={perkStyles.cdPerkItemRight}>
                                        <p>{perk.content}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    } )}
                </Swiper>
                <div className={` ${perkStyles.cdPerkNext} cd-swiper-arrows cd-next-arrow cd-perk-next`} ><div className={`cd-arrow`}></div></div>
                <div className={` ${perkStyles.cdPerkPrev} cd-swiper-arrows cd-prev-arrow cd-perk-prev`} ><div className={`cd-arrow`}></div></div>
            </div>
       </section>
    )
}

export default PerkSwiper
