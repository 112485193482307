// Dependency
import React from "react"
import { ParallaxBanner } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';

// Styles
import * as heroStyles from './hero.module.scss'

// Assets

// Template
const Hero = (props) => {

    const featuredImage = props.featuredImage;

    return (
        <ParallaxBanner
            className={heroStyles.cdHero}
            layers={[
                {
                    image: featuredImage,
                    amount: -0.2,
                }
            ]}
        >
            <div className={`${heroStyles.cdBackgroundOverlay} cd-background-overlay`}></div>
            <div className="cd-max-width">
                <div className={heroStyles.cdHeroContent}>
                    <Fade >
                        {props.children}
                    </Fade>
                </div>
            </div>
        </ParallaxBanner>
    )
}

export default Hero
